import * as React from "react";
import { useState } from "react";

import {
  Datagrid,
  TextField,
  ReferenceManyField,
  useRecordContext,
  useCreatePath,
  BulkDeleteButton,
  BulkDeleteButtonProps,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
  useRedirect,
  useGetIdentity,
  useDataProvider,
  useRefresh
} from "react-admin";
import { Switch, Typography, styled } from "@mui/material";
import PersonOffIcon from "@mui/icons-material/PersonOff";

import { useFlags } from "../../../../clients/launchDarkly";
import { runeTheme } from "../../../common/RuneTheme";
import { Project } from "../../../../types";
import AggregatedMetrics from "./AggregatedMetrics";

import { RuneDateField } from "../../../fields/RuneDateField";

import { StudyDatagridHeader } from "../../../common/StudyDataGrid/StudyDatagridHeader";
import TertiaryActionButton from "../../../common/buttons/TertiaryActionButton";
import { AddToCohortButton } from "./AddToCohortButton";
import EditCohortDialog from "../../cohort/CohortEdit";
import iconEdit from "../../../../shared/icon/edit.svg";
import { NoResourceMessage } from "../../../common/NoResourceMessage";
import { DiagnosticField } from "src/components/fields/DiagnosticField";
import { TimeInterval } from "src/constants";
import {
  ProjectDisplayConfig,
  ProjectDisplayConfigurationDialog
} from "./ProjectDisplayConfigurationDialog";
import { DataRecencyField } from "src/components/fields/DataRecencyField";
import DataAvailabilityHeatmapCalendarField from "src/components/fields/DataAvailabilityHeatmapCalendarField";
import ProjectPatientUpdateDialog from "../../projectpatient/ProjectPatientUpdate";
import { PatientQuickLinksField } from "src/components/fields/PatientQuickLinksField";
import TapTestField from "src/components/fields/TapTestField";

const NoPatientMessage = () => (
  <NoResourceMessage>No patients.</NoResourceMessage>
);

const ProjectConfiguration = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: "24px",
  alignItems: "center",
  width: "100%"
});

const TertiaryActions = styled("div")({
  alignItems: "center",

  display: "flex",
  padding: "16px",
  gap: "4px",
  height: "19px",
  margin: "8px"
});

const EditProjectButton = () => {
  const project = useRecordContext<Project>();
  const createPath = useCreatePath();
  return (
    <TertiaryActionButton
      link={createPath({
        type: "edit",
        resource: "Project",
        id: project.id
      })}
      state={{
        record: {
          project_id: project.id,
          project_title: project.title,
          status: "UNKNOWN_PATIENT"
        }
      }}
      icon={iconEdit}
      label="Edit Project"
    />
  );
};

const RuneBulkDeleteButton = (props: BulkDeleteButtonProps) => {
  return (
    <BulkDeleteButton
      sx={{
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "22px",
        letterSpacing: "-0.03em",
        textDecorationLine: "underline",
        color: runeTheme.palette.primary.main,
        textTransform: "none",
        alignItems: "center"
      }}
      label="Remove Patients"
      confirmContent="Are you sure you want to remove the selected patients?"
      mutationMode="pessimistic"
      icon={<PersonOffIcon />}
      {...props}
    />
  );
};

const ProjectPatientActionButtons = (props: {
  isCohort?: boolean;
  cohortId?: string;
}) => {
  const project = useRecordContext<Project>();
  const { isCohort, cohortId } = props;
  const cohorts = project?.cohortList?.cohorts
    ? project.cohortList.cohorts
    : [];
  const { cohortTabsVisible } = useFlags();

  return (
    <>
      {!isCohort && (
        <>
          {cohortTabsVisible && cohorts.length > 0 && (
            <AddToCohortButton
              id={"addPatientsToCohort"}
              label={"Add patients to cohort"}
              menuItems={cohorts}
            />
          )}
          <RuneBulkDeleteButton
            confirmTitle="Remove Patients from Project"
            mutationOptions={{ meta: { projectId: project.id } }}
          />
        </>
      )}
      {cohortTabsVisible && isCohort && (
        <RuneBulkDeleteButton
          confirmTitle="Remove Patients from Cohort"
          mutationOptions={{ meta: { cohortId: cohortId } }}
        />
      )}
    </>
  );
};

const ShowPIIToggle = (props: {
  isDisplayPIIEnabled: boolean;
  setDisplayPIIEnabled: (isDisplayPIIEnabled: boolean) => boolean;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setDisplayPIIEnabled(event.target.checked);
  };

  return (
    <>
      <Switch
        color="primary"
        value={props.isDisplayPIIEnabled}
        onChange={handleChange}
      />

      <Typography
        sx={{
          fontSize: "16px",
          lineHeight: "19px",
          letterSpacing: "-0.03em",
          textTransform: "capitalize",
          fontWeight: "600",
          color: runeTheme.palette.button.tertiary.text
        }}
      >
        Display PII
      </Typography>
    </>
  );
};

// https://runelabs.atlassian.net/browse/SW-2470
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const PatientListHeader = (props: any) => {
  const {
    qcMetricSelectedIndex,
    setQcMetricSelectedIndex,
    isDisplayPIIEnabled,
    setDisplayPIIEnabled,
    projectDisplayConfig,
    setProjectDisplayConfig,
    isCohort,
    cohortId,
    dataCategoryCounts,
    setQcMetricSelectedCategory
  } = props;

  const { data: user } = useGetIdentity();
  const canSeePII = user?.defaultMembership?.role.canSeePHI;

  const canConfigureProjectDisplay =
    user?.defaultMembership?.role.admin ||
    user?.defaultMembership?.role.superAdmin;

  return (
    <div
      style={{
        background: "#DBEEFB",
        borderRadius: "24px 24px 0px 0px"
      }}
    >
      <ProjectConfiguration>
        <div></div>
        <TertiaryActions>
          {canSeePII && (
            <ShowPIIToggle
              isDisplayPIIEnabled={isDisplayPIIEnabled}
              setDisplayPIIEnabled={setDisplayPIIEnabled}
            />
          )}
          {isCohort ? (
            <EditCohortDialog cohortId={cohortId} />
          ) : (
            <EditProjectButton />
          )}
          {canConfigureProjectDisplay && (
            <ProjectDisplayConfigurationDialog
              projectDisplayConfig={projectDisplayConfig}
              setProjectDisplayConfig={setProjectDisplayConfig}
            />
          )}
        </TertiaryActions>
      </ProjectConfiguration>
      <AggregatedMetrics
        qcMetricSelectedIndex={qcMetricSelectedIndex}
        setQcMetricSelectedIndex={setQcMetricSelectedIndex}
        projectDisplayConfig={projectDisplayConfig}
        dataCategoryCounts={dataCategoryCounts}
        setQcMetricSelectedCategory={setQcMetricSelectedCategory}
      />
    </div>
  );
};

const PatientExpandView = (props: {
  projectDisplayConfig: ProjectDisplayConfig;
  dataAvailabilityValue: string;
  project: Project;
}) => {
  const { projectDisplayConfig, dataAvailabilityValue, project } = props;
  const { displayExternalLinks } = useFlags();

  return (
    <div>
      <DataAvailabilityHeatmapCalendarField
        projectDisplayConfig={projectDisplayConfig}
        dataAvailability={dataAvailabilityValue}
        projectStart={project.startedAt}
      />
      {displayExternalLinks && <PatientQuickLinksField />}
    </div>
  );
};

// https://runelabs.atlassian.net/browse/SW-2470
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const PatientList = (props: any) => {
  const {
    dataAvailabilityValue,
    qcMetricSelectedIndex,
    groupedLabels,
    isDisplayPIIEnabled,
    projectDisplayConfig,
    isCohort,
    cohortId,
    displayOnboardedDate,
    metricCategories,
    displayDiagnosticInfo,
    qcMetricSelectedCategory
  } = props;

  const project = useRecordContext<Project>();

  const { data: user } = useGetIdentity();
  const isAdmin =
    user?.defaultMembership?.role.admin ||
    user?.defaultMembership?.role.superAdmin;

  const canSeePII = user?.defaultMembership?.role.canSeePHI;

  const dateOptions = { year: "numeric", month: "short", day: "numeric" };
  const { patientStartEndDate } = useFlags();

  const filter = {
    dataAvailability: dataAvailabilityValue,
    qcMetric: qcMetricSelectedIndex,
    cohortId: cohortId,
    projectStartDate: project.startedAt,
    canSeePII: canSeePII,
    metricCategories: metricCategories,
    qcMetricCategory: qcMetricSelectedCategory
  };
  if (isCohort && cohortId) filter.cohortId = cohortId;

  return (
    <ReferenceManyField
      label="Patients"
      reference={isCohort ? "CohortPatient" : "ProjectPatient"}
      target={isCohort ? "cohort_id" : "project_id"}
      filter={filter}
    >
      <Datagrid
        empty={<NoPatientMessage />}
        header={<StudyDatagridHeader groupedLabels={groupedLabels} />}
        expand={
          <PatientExpandView
            projectDisplayConfig={projectDisplayConfig}
            dataAvailabilityValue={dataAvailabilityValue}
            project={project}
          />
        }
        bulkActionButtons={
          <ProjectPatientActionButtons
            isCohort={isCohort}
            cohortId={cohortId}
          />
        }
      >
        <CodeNameNameRedirectTextField source="codeName" />
        {isAdmin && <TextField source="appMode" />}
        {isDisplayPIIEnabled && (
          <TextField source="realName" label="Full name" />
        )}
        {isDisplayPIIEnabled && <TextField source="email" label="email" />}

        {displayOnboardedDate && !patientStartEndDate && (
          <RuneDateField
            source="createdAt"
            label="Onboarded since"
            dateFmtOptions={dateOptions}
          />
        )}
        {patientStartEndDate && (
          <RuneDateField
            source="projectStartTime"
            label="Start Date"
            dateFmtOptions={dateOptions}
          />
        )}
        {patientStartEndDate && (
          <RuneDateField
            source="projectEndTime"
            label="End Date"
            dateFmtOptions={dateOptions}
          />
        )}
        {projectDisplayConfig.dataTypes.tremor_dyskinesia.enabled && (
          <DataRecencyField
            source="TREMOR_DYSKINESIA"
            label="Tremor & Dyskinesia"
            category="tremor_dyskinesia"
          />
        )}
        {projectDisplayConfig.dataTypes.mobility.enabled && (
          <DataRecencyField
            source="MOBILITY"
            label="Mobility"
            category="mobility"
          />
        )}
        {projectDisplayConfig.dataTypes.sleep.enabled && (
          <DataRecencyField source="SLEEP" label="Sleep" category="sleep" />
        )}
        {projectDisplayConfig.dataTypes.tap_test?.enabled && (
          <TapTestField
            source="TAP_TEST"
            label="Tap Test"
            category="tap_test"
            projectStart={project.startedAt}
          />
        )}
        {displayDiagnosticInfo && (
          <DiagnosticField source="diagnostic_info" label="Diagnostic Info" />
        )}
        {isAdmin && (
          <ProjectPatientUpdateDialog projectId={project.id.toString()} />
        )}
      </Datagrid>
    </ReferenceManyField>
  );
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const CodeNameNameRedirectTextField = (props: any) => {
  const record = useRecordContext();
  const redirect = useRedirect();

  return (
    <TextField
      source="codeName"
      sx={{
        textDecoration: "underline",
        color: runeTheme.palette.button.tertiary.text
      }}
      onClick={() => {
        redirect("show", "Patient", record.id);
      }}
    />
  );
};

CodeNameNameRedirectTextField.defaultProps = { label: "Code Name" };

const ProjectTabs = () => {
  const project = useRecordContext<Project>();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirect = useRedirect();
  let cohorts = project?.cohortList?.cohorts;
  cohorts = cohorts ? cohorts : [];

  const { cohortTabsVisible, patientStartEndDate } = useFlags();

  const { data: user } = useGetIdentity();
  const isAdmin =
    user?.defaultMembership?.role.admin ||
    user?.defaultMembership?.role.superAdmin;
  const isSuperAdmin = user?.defaultMembership?.role.superAdmin;

  let displayOnboardedDate = false;
  let expandedPatientViewEnabled = false;
  const metricCategories: string[] = [];

  const [dataAvailabilityValue, setDataAvailabilityValue] = useState(
    TimeInterval.NINETY_DAYS
  );

  const [qcMetricSelectedIndex, setQcMetricSelectedIndex] = useState(0);
  const [qcMetricSelectedCategory, setQcMetricSelectedCategory] =
    useState("ALL_PATIENTS");

  const [isDisplayPIIEnabled, setDisplayPIIEnabled] = useState(false);

  const defaultConfig = {
    dataTypes: {
      tremor_dyskinesia: { enabled: true, threshold: 6 },
      mobility: { enabled: true, threshold: 0 },
      sleep: { enabled: true, threshold: 1 },
      medication: { enabled: false, threshold: 0 },
      tap_test: { enabled: true }
    },
    visualization: {
      highlightMissingData: true
    }
  };

  const parsedConfig = project.config
    ? JSON.parse(project.config)["ui_config"]
    : {};
  const projectDisplayConfigValue = Object.keys(parsedConfig).length
    ? parsedConfig
    : defaultConfig;

  const [projectDisplayConfig, setProjectDisplayConfigState] =
    useState<ProjectDisplayConfig>(projectDisplayConfigValue);

  // Custom setter function that also updates the backend
  const setProjectDisplayConfig = async (newConfig: ProjectDisplayConfig) => {
    setProjectDisplayConfigState(newConfig);
    try {
      await dataProvider.update("Project", {
        id: project.id,
        data: { id: project.id, config: newConfig },
        previousData: project
      });
      refresh();
      redirect("show", "Project", project.id, { forceRefresh: true });
      // Handle successful update, e.g., show notification
    } catch (error) {
      // Handle error, e.g., show error notification
      console.error("Failed to update project config:", error);
    }
  };

  displayOnboardedDate = true;
  const displayDiagnosticInfo = false;
  expandedPatientViewEnabled = true;

  if (projectDisplayConfig.dataTypes.tremor_dyskinesia.enabled)
    metricCategories.push("TREMOR_DYSKINESIA");
  if (projectDisplayConfig.dataTypes.mobility.enabled)
    metricCategories.push("MOBILITY");
  if (projectDisplayConfig.dataTypes.sleep.enabled)
    metricCategories.push("SLEEP");
  if (projectDisplayConfig.dataTypes.tap_test?.enabled)
    metricCategories.push("TAP_TEST");

  const groupedLabels = [
    {
      groupName: "",
      key: "group-identification",
      subLabels: [
        { name: "Code Name", key: "code-name", sortable: true },
        isAdmin && {
          name: "App Mode",
          key: "appMode",
          sortable: true
        },
        isDisplayPIIEnabled && {
          name: "Full name",
          key: "full-name",
          sortable: true
        },
        isDisplayPIIEnabled && {
          name: "email",
          key: "email",
          sortable: true
        },
        patientStartEndDate && {
          name: "Start Date",
          key: "projectStartTime",
          sortable: true
        },
        patientStartEndDate && {
          name: "End Date",
          key: "projectEndTime",
          sortable: true
        }
      ].filter(Boolean)
    },

    {
      groupName: "Data Availability",
      key: "group-data-availability",
      subLabels: [
        projectDisplayConfig.dataTypes.tremor_dyskinesia.enabled && {
          name: "Tremor & Dyskinesia",
          source: "TREMOR_DYSKINESIA",
          key: "tremor-dyskinesia",
          sortable: false
        },
        projectDisplayConfig.dataTypes.mobility.enabled && {
          name: "Mobility",
          key: "mobility",
          sortable: false
        },
        projectDisplayConfig.dataTypes.sleep.enabled && {
          name: "Sleep",
          key: "sleep",
          sortable: false
        },
        projectDisplayConfig.dataTypes.tap_test?.enabled && {
          name: "Tap Test",
          key: "tap-test",
          sortable: false
        },
        displayDiagnosticInfo && {
          name: "Diagnostic",
          key: "diagnostic",
          source: "diagnostic_info",
          sortable: false
        }
      ].filter(Boolean)
    }
  ].filter(Boolean);

  return (
    <TabbedShowLayout
      sx={{
        background: runeTheme.palette.background.default,
        overflowX: "scroll"
      }}
      tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />}
    >
      <Tab label="All" sx={{ textTransform: "capitalize" }}>
        <PatientListHeader
          qcMetricSelectedIndex={qcMetricSelectedIndex}
          setQcMetricSelectedIndex={setQcMetricSelectedIndex}
          isDisplayPIIEnabled={isDisplayPIIEnabled}
          setDisplayPIIEnabled={setDisplayPIIEnabled}
          projectDisplayConfig={projectDisplayConfig}
          setProjectDisplayConfig={setProjectDisplayConfig}
          dataCategoryCounts={project?.dataCategoryCounts}
          setQcMetricSelectedCategory={setQcMetricSelectedCategory}
        />
        <PatientList
          dataAvailabilityValue={dataAvailabilityValue}
          qcMetricSelectedIndex={qcMetricSelectedIndex}
          groupedLabels={groupedLabels}
          isDisplayPIIEnabled={isDisplayPIIEnabled}
          projectDisplayConfig={projectDisplayConfig}
          metricCategories={metricCategories}
          displayOnboardedDate={displayOnboardedDate}
          displayDiagnosticInfo={displayDiagnosticInfo}
          qcMetricSelectedCategory={qcMetricSelectedCategory}
        />
      </Tab>
      {cohortTabsVisible &&
        // https://runelabs.atlassian.net/browse/SW-2470
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        cohorts.map((value: any) => (
          <Tab
            label={value.title}
            key={value.id}
            sx={{ textTransform: "capitalize" }}
          >
            <PatientListHeader
              qcMetricSelectedIndex={qcMetricSelectedIndex}
              setQcMetricSelectedIndex={setQcMetricSelectedIndex}
              isDisplayPIIEnabled={isDisplayPIIEnabled}
              setDisplayPIIEnabled={setDisplayPIIEnabled}
              projectDisplayConfig={projectDisplayConfig}
              setProjectDisplayConfig={setProjectDisplayConfig}
              dataCategoryCounts={value.dataCategoryCounts}
              setQcMetricSelectedCategory={setQcMetricSelectedCategory}
              isCohort
              cohortId={value.id}
            />
            <PatientList
              dataAvailabilityValue={dataAvailabilityValue}
              qcMetricSelectedIndex={qcMetricSelectedIndex}
              groupedLabels={groupedLabels}
              isDisplayPIIEnabled={isDisplayPIIEnabled}
              projectDisplayConfig={projectDisplayConfig}
              metricCategories={metricCategories}
              displayOnboardedDate={displayOnboardedDate}
              displayDiagnosticInfo={displayDiagnosticInfo}
              qcMetricSelectedCategory={qcMetricSelectedCategory}
              isCohort
              cohortId={value.id}
            />
          </Tab>
        ))}
    </TabbedShowLayout>
  );
};

export default ProjectTabs;
