import moment from "moment-timezone";
import { Identifier } from "react-admin";
import { DataType } from "src/components/fields/DataAvailabilityHeatmapCalendarField";
import {
  MILLI_SEC_PER_DAY,
  SEC_PER_DAY,
  SEC_PER_HOUR,
  SEC_PER_WEEK,
  TimeInterval
} from "src/constants";

export interface StreamMetadata {
  id: string;
  algorithm: string;
  maxTime: number;
  minTime: number;
  deviceId: string;
  measurement: string;
  hkAggregation: string;
  streamType: {
    id: string;
    name: string;
    description: string;
  };
  streamTypeId: string;
  parameters: {
    key: string;
    value: string;
  }[];
}

export const getDiagnosticStreamParams = (patientId: string): object => {
  return {
    meta: {
      filters: {
        patientId: patientId,
        algorithm: "ingest-rune-events.0",
        parameters: [{ key: "category", value: "device_info" }]
      }
    }
  };
};

export const getTremorStreamParams = (patientId: string): object => {
  return {
    meta: {
      filters: {
        patientId: patientId,
        streamTypeId: "duration",
        deviceId: "all",
        algorithm: "ingest-strive-applewatch-md.0",
        parameters: [
          { key: "category", value: "symptom" },
          { key: "measurement", value: "tremor" },
          { key: "severity", value: "all" }
        ]
      }
    }
  };
};

export const getTapTestStreamParams = (patientId: Identifier): object => {
  return {
    meta: {
      filters: {
        algorithm: "ingest-strive-taptest.0",
        deviceId: "all",
        patientId: patientId,
        parameters: [
          { key: "category", value: "assessment" },
          { key: "measurement", value: "statistics" }
        ]
      }
    }
  };
};

export const getSleepStageStreamParams = (patientId: string): object => {
  return {
    meta: {
      filters: {
        patientId: patientId,
        streamTypeId: "boolean",
        deviceId: "all",
        algorithm: "ingest-strive-healthkit.1",
        parameters: [
          { key: "category", value: "sleep" },
          { key: "measurement", value: "sleep_state" },
          { key: "sleep_status", value: "asleep" },
          { key: "source_device", value: "apple_health" }
        ]
      }
    }
  };
};

export const getMobilityStreamParams = (patientId: string): object => {
  return {
    meta: {
      filters: {
        patientId: patientId,
        algorithm: "ingest-strive-healthkit.1",
        deviceId: "all",
        parameters: [
          { key: "category", value: "motion" },
          { key: "measurement", value: "step_count" },
          { key: "source_device", value: "iphone" }
        ]
      }
    }
  };
};

export const getMedicationStreamParams = (patientId: string): object => {
  return {
    meta: {
      filters: {
        patientId: patientId,
        algorithm: "ingest-rune-events.0",
        parameters: [{ key: "measurement", value: "medication" }]
      }
    }
  };
};

export const getProStreamsParams = (patientId: string): object => {
  return {
    meta: {
      filters: {
        patientId: patientId,
        algorithm: "ingest-rune-events.0",
        parameters: [{ key: "category", value: "patient_report" }]
      }
    }
  };
};

export const getTremorSeverityAllStreamsParams = (patientId: string) => {
  return {
    meta: {
      filters: {
        patientId: patientId,
        streamTypeId: "duration",
        algorithm: "ingest-strive-applewatch-md.0",
        deviceId: "all",
        parameters: [
          {
            key: "category",
            value: "symptom"
          },
          {
            key: "measurement",
            value: "tremor"
          },
          {
            key: "severity",
            value: "all"
          }
        ]
      }
    }
  };
};

export const getAllTremorStreamsParams = (patientId: string) => {
  return {
    meta: {
      filters: {
        patientId: patientId,
        streamTypeId: "percentage",
        algorithm: "ingest-strive-applewatch-md.0",
        deviceId: "all",
        parameters: [
          {
            key: "category",
            value: "symptom"
          },
          {
            key: "measurement",
            value: "tremor"
          }
        ]
      }
    }
  };
};

export const getAllDyskinesiaStreamsParams = (patientId: string) => {
  return {
    meta: {
      filters: {
        patientId: patientId,
        streamTypeId: "percentage",
        algorithm: "ingest-strive-applewatch-md.0",
        deviceId: "all",
        parameters: [
          {
            key: "category",
            value: "symptom"
          },
          {
            key: "measurement",
            value: "dyskinesia"
          }
        ]
      }
    }
  };
};

export const getHkHeartrateStreamsParams = (patientId: string) => {
  return {
    meta: {
      filters: {
        patientId: patientId,
        algorithm: "ingest-strive-healthkit.1",
        deviceId: "all",
        parameters: [
          {
            key: "category",
            value: "vitals"
          },
          {
            key: "measurement",
            value: "heart_rate"
          }
        ]
      }
    }
  };
};

export function getDataAvailabilityConfiguration(
  dataAvailability: string,
  projectStart: Date
) {
  const today = new Date();
  let startDate = new Date();
  let aggregateWindowResolution = 0;
  let availabilityResolution = 0;

  switch (dataAvailability) {
    case TimeInterval.FOURTEEN_DAYS:
      startDate = new Date(today.getTime() - 14 * MILLI_SEC_PER_DAY);
      break;
    case TimeInterval.NINETY_DAYS:
      startDate = new Date(today.getTime() - 90 * MILLI_SEC_PER_DAY);
      break;
    case TimeInterval.PROJECT_ALL:
      startDate = new Date(projectStart);
      break;
  }

  if (today.getTime() - startDate.getTime() <= 30 * MILLI_SEC_PER_DAY) {
    // less than a month, we can do daily, with 1-hour for availability
    aggregateWindowResolution = SEC_PER_DAY;
    availabilityResolution = SEC_PER_HOUR; // 1 hour
  } else if (today.getTime() - startDate.getTime() <= 90 * MILLI_SEC_PER_DAY) {
    aggregateWindowResolution = SEC_PER_DAY; // Day resolution for period between 30 and 90 days
    availabilityResolution = 4 * SEC_PER_HOUR; // 4 hour
  } else {
    aggregateWindowResolution = SEC_PER_WEEK; // Week resolution for period over 90 days
    availabilityResolution = 12 * SEC_PER_HOUR; // 12 hour
  }

  return {
    startDate,
    aggregateWindowResolution,
    availabilityResolution
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function getAggregateWindowPromises(
  streamsMetadata: any,
  dataProvider: any,
  aggregateWindowResolution: number,
  startDateTimestamp: number,
  endDateTimestamp: number
) {
  // get browser time zone for now
  const browserTimezone = moment.tz.guess();

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const streamsMetadataAggregateWindowPromises: Promise<any>[] = [];

  // We split the aggregate window endpoint per year (limit of the aggregate_window)
  for (
    let startTime = startDateTimestamp;
    startTime <= endDateTimestamp;
    startTime += 365 * 24 * 60 * 60
  ) {
    const endTime = Math.min(
      startTime + 365 * 24 * 60 * 60 - 1,
      endDateTimestamp
    );
    for (
      let streamIndex = 0;
      streamIndex < streamsMetadata.data.length;
      streamIndex++
    ) {
      streamsMetadataAggregateWindowPromises.push(
        dataProvider.getStreamAggregateWindow(
          streamsMetadata.data[streamIndex].id,
          {
            start_time: JSON.stringify(startTime),
            end_time: endTime,
            resolution: JSON.stringify(aggregateWindowResolution),
            aggregate_function: "sum",
            // TODO: SW-3563: Timezone are currently not handled correctly.
            // It should be taking into account the timezone of the user instead of being the browser timezone.
            // A full revamp/revisit/refactor of the timezone management is expected
            timezone_name: browserTimezone
          }
        )
      );
    }
  }
  return streamsMetadataAggregateWindowPromises;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function getStreamAvailabilityPromises(
  streamsMetadata: any,
  dataProvider: any,
  availabilityResolution: number,
  startDateTimestamp: number,
  endDateTimestamp: number
) {
  // get browser time zone for now
  const browserTimezone = moment.tz.guess();

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const streamsMetadataAvailabilityPromises: Promise<any>[] = [];
  // We split the stream availability calls per month (to get over the 10000 item limit)
  for (
    let startTime = startDateTimestamp;
    startTime <= endDateTimestamp;
    startTime += 30 * 24 * 60 * 60
  ) {
    const endTime = Math.min(
      startTime + 30 * 24 * 60 * 60 - 1,
      endDateTimestamp
    );
    for (
      let streamIndex = 0;
      streamIndex < streamsMetadata.data.length;
      streamIndex++
    ) {
      // We only consider streams that have a relevant time window
      if (
        startTime < streamsMetadata.data[streamIndex].maxTime &&
        endTime > streamsMetadata.data[streamIndex].minTime
      ) {
        streamsMetadataAvailabilityPromises.push(
          dataProvider.getStreamAvailability(
            streamsMetadata.data[streamIndex].id,
            {
              start_time: JSON.stringify(startTime),
              end_time: JSON.stringify(endTime),
              resolution: JSON.stringify(availabilityResolution),
              aggregate_function: "sum",
              // TODO: SW-3563: Timezone are currently not handled correctly.
              // It should be taking into account the timezone of the user instead of being the browser timezone.
              // A full revamp/revisit/refactor of the timezone management is expected
              timezone_name: browserTimezone,
              format: "json"
            }
          )
        );
      }
    }
  }

  return streamsMetadataAvailabilityPromises;
}

export const labelPromisesResults = (
  promises: Promise<number>[],
  label: string
): Promise<{ result: any; label: string }[]> =>
  Promise.all(
    promises.map((promise) => promise.then((result) => ({ result, label })))
  );

export function initializeDataAvailabilityData(
  tremorStreamsAggregateWindow: any,
  startDateTimestamp: number,
  endDateTimestamp: number,
  aggregateWindowResolution: number
) {
  const dataAvailabilityData: any = {};

  // We "prefill" all the timepoints from start to end
  // Probably need more  timezone magic

  // If the tremor stream is empty or no streams is matching the time window, we use the start date as the first timestamp
  let firstTimeStampFromTD = startDateTimestamp;

  for (
    let streamIndex = 0;
    streamIndex < tremorStreamsAggregateWindow.length;
    streamIndex++
  ) {
    const meanStreamValue =
      tremorStreamsAggregateWindow[streamIndex].result.json.summary.value_mean;

    // We ignore empty streams (older devices, different start dates..)
    if (meanStreamValue !== null && meanStreamValue !== 0) {
      firstTimeStampFromTD =
        tremorStreamsAggregateWindow[streamIndex].result.json.data.time[1];
      break;
    }
  }

  let startDateBucketTimestamp = firstTimeStampFromTD;

  // If the first timestamp from the stream is "after" the start date, we walk backwards in aggregateWindowResolution increments
  while (startDateBucketTimestamp > startDateTimestamp) {
    startDateBucketTimestamp -= aggregateWindowResolution;
  }

  for (
    let startTime = startDateBucketTimestamp;
    startTime <= endDateTimestamp;
    startTime += aggregateWindowResolution
  ) {
    dataAvailabilityData[startTime] = {
      tremorDuration: 0,
      hkHeartrateDuration: 0
    };
  }
  return dataAvailabilityData;
}

export function populateDataAvailabilityFromAggregateWindowStreams(
  dataAvailabilityData: any,
  streamsAggregateWindow: any,
  dataCategoryName: string
) {
  const nanoSecondsPerHour = 1e9 * 60 * 60;
  for (
    let streamIndex = 0;
    streamIndex < streamsAggregateWindow.length;
    streamIndex++
  ) {
    const curStreamResultCardinality =
      streamsAggregateWindow[streamIndex].result.json.cardinality;
    const curStreamResultData =
      streamsAggregateWindow[streamIndex].result.json.data;
    for (
      let dataPointIndex = 0;
      dataPointIndex < curStreamResultCardinality;
      dataPointIndex++
    ) {
      if (curStreamResultData.duration_sum[dataPointIndex] != 0) {
        if (
          !(curStreamResultData.time[dataPointIndex] in dataAvailabilityData)
        ) {
          dataAvailabilityData[curStreamResultData.time[dataPointIndex]] = {};
        }

        const previousValue =
          dataAvailabilityData[curStreamResultData.time[dataPointIndex]][
            dataCategoryName
          ] || 0;
        dataAvailabilityData[curStreamResultData.time[dataPointIndex]][
          dataCategoryName
        ] =
          previousValue +
          curStreamResultData.duration_sum[dataPointIndex] / nanoSecondsPerHour;
      }
    }
  }
}

export function bucketAvaibilityStreamsForExistingTimestamps(
  streamsAvailabilityPromises: any,
  dataAvailabilityData: any,
  availabilityResolution: number,
  dataCategoryName: string
) {
  // Get the list of keys (timestamps) from dataAvailabilityData
  const timestamps = Object.keys(dataAvailabilityData);
  timestamps.sort();

  for (
    let timestampIndex = 0;
    timestampIndex < timestamps.length;
    timestampIndex++
  ) {
    const startTimestamp = timestamps[timestampIndex];
    const endTimestamp =
      timestampIndex == timestamps.length - 1
        ? new Date().getTime()
        : timestamps[timestampIndex + 1];

    let countIntervalForThisDay = 0;

    for (
      let streamIndex = 0;
      streamIndex < streamsAvailabilityPromises.length;
      streamIndex++
    ) {
      const curStreamResultCardinality =
        streamsAvailabilityPromises[streamIndex].result.json.cardinality;
      const curStreamResultData =
        streamsAvailabilityPromises[streamIndex].result.json.data;
      for (
        let dataPointIndex = 0;
        dataPointIndex < curStreamResultCardinality;
        dataPointIndex++
      ) {
        const timestamp = curStreamResultData.time[dataPointIndex];
        const availability = curStreamResultData.availability[dataPointIndex];

        if (
          timestamp >= startTimestamp &&
          timestamp < endTimestamp &&
          availability === 1
        ) {
          countIntervalForThisDay++;
        }
      }
    }

    dataAvailabilityData[startTimestamp][dataCategoryName] =
      (countIntervalForThisDay * availabilityResolution) / (60 * 60);
  }
}

export const getStreamIdsForCategories = async ({
  dataProvider,
  desiredCategories,
  enabledCategories,
  patientId,
  streamParamsFunctions
}: {
  dataProvider: any;
  desiredCategories: Array<keyof DataType>;
  enabledCategories: Array<keyof DataType>;
  patientId: Identifier;
  streamParamsFunctions: any;
}) => {
  const streamIdsPromises = enabledCategories
    .filter((key: keyof DataType) => desiredCategories.includes(key))
    .map((category: keyof DataType) => {
      console.log({ category });

      if (
        streamParamsFunctions[category as keyof typeof streamParamsFunctions]
      ) {
        const params = streamParamsFunctions[
          category as keyof typeof streamParamsFunctions
        ](patientId.toString());
        return dataProvider.getStreamIds(params).then((streamIds: string[]) => {
          return { category, streamIds }; // Return an object with both category and streamIds
        });
      }
      return Promise.resolve(null); // Return null for categories without a function mapping
    });

  return await Promise.all(streamIdsPromises);
};
