import moment from "moment-timezone";

export const getBrowserTimezone = (): string => moment.tz.guess();

export const deriveTimestampsFromDate = (
  startDate: Date
): { startDateTimestamp: number; endDateTimestamp: number } => {
  // Using the browser timezone is not optimal. This is a temporary solution.
  const browserTimezone = getBrowserTimezone();
  const dateString = startDate.toLocaleDateString("en-US", {
    timeZone: browserTimezone
  });
  const midnightUTCDate = new Date(dateString);
  midnightUTCDate.setHours(17, 0, 0, 0);

  const startDateTimestamp = Math.floor(midnightUTCDate.getTime() / 1000);
  const endDateTimestamp = Math.floor(Date.now() / 1000);

  return { startDateTimestamp, endDateTimestamp };
};
