import React, { useEffect, useRef, useState, useCallback } from "react";
import { ProjectDisplayConfig } from "../resources/project/ProjectShow/ProjectDisplayConfigurationDialog";
import { useDataProvider, useGetIdentity, useRecordContext } from "react-admin";
import {
  getDataAvailabilityConfiguration,
  getMobilityStreamParams,
  getSleepStageStreamParams,
  getTremorStreamParams,
  getStreamIdsForCategories
} from "src/providers/streams/runeStreamsUtils";
import {
  deriveTimestampsFromDate,
  getBrowserTimezone
} from "src/utils/dateTimeUtils";
import { get } from "lodash";

import { COLORS } from "../common/colors";
import { useMediaQuery } from "@mui/material";
import { useFlags } from "../../clients/launchDarkly";

// Define the types for your data and events
export type DataType = {
  date: string;
  patientId: string;
  tremor_dyskinesia?: number;
  mobility?: number;
  sleep?: number;
  medication?: Record<string, number>;
  tap_test?: number;
};

const getColor = (
  value: number,
  threshold: number,
  date: string,
  onboardedDate: Date | null,
  startDate: Date | null,
  endDate: Date | null,
  key: keyof DataType,
  projectDisplayConfig: ProjectDisplayConfig
): string => {
  const colorMode: "YELLOW_RED" | "CLINICIAN_CALENDAR" = projectDisplayConfig
    .visualization.highlightMissingData
    ? "YELLOW_RED"
    : "CLINICIAN_CALENDAR";

  switch (colorMode) {
    case "YELLOW_RED":
      if (startDate && new Date(date) < startDate) {
        return COLORS.GREY_400;
      }
      if (endDate && new Date(date) > endDate) {
        return COLORS.GREY_400;
      }
      if (value > threshold || (threshold === undefined && value > 0))
        return COLORS.PRIMARY_300;
      if (value > 0) return COLORS.YELLOW_200;
      return COLORS.RED_200;
    case "CLINICIAN_CALENDAR":
      if (value > 0) {
        if (startDate && new Date(date) < startDate) {
          return COLORS.GREY_200;
        }
        if (endDate && new Date(date) > endDate) {
          return COLORS.GREY_200;
        }
        if (key === "tremor_dyskinesia") {
          return COLORS.PRIMARY_1200;
        }
        if (key === "mobility") {
          return COLORS.PRIMARY_700;
        }
        if (key === "sleep") {
          return COLORS.PRIMARY_400;
        }
        console.log(`the datatype ${key} is not handled`);
        return COLORS.RED_200;
      }
      return COLORS.GREY_200;
  }
};

const isMonday = (dateString: string): boolean => {
  const date = new Date(dateString);
  return date.getDay() === 1;
};

const isFirstOfMonth = (dateString: string): boolean => {
  const date = new Date(dateString);
  return date.getDate() === 1;
};

const getMonthLabelPositions = (data: DataType[]) => {
  const labels: { text: string; position: number }[] = [];
  let lastMonth = "";
  let firstMonthDays = 0;

  data.forEach((entry, index) => {
    const currentDate = new Date(entry.date);
    const month = currentDate.toLocaleString("default", { month: "short" });

    if (month !== lastMonth) {
      if (index === 0) {
        // This is the first month: we calculate the number of days in this month.
        // If less than 8 days, we don't display the month (fix the overlapping months issue)
        const nextMonth = new Date(currentDate);
        nextMonth.setMonth(currentDate.getMonth() + 1);
        const lastDayOfMonth = new Date(
          nextMonth.getFullYear(),
          nextMonth.getMonth(),
          0
        );
        firstMonthDays = lastDayOfMonth.getDate() - currentDate.getDate() + 1;
      }

      if (labels.length > 0 || firstMonthDays >= 8) {
        labels.push({ text: month, position: index });
      }

      lastMonth = month;
    }
  });

  return labels;
};

const getLegendText = (key: keyof DataType): string => {
  const legends: { [key in keyof DataType]?: string } = {
    tremor_dyskinesia: "T&D",
    mobility: "Mobility",
    sleep: "Sleep",
    medication: "Meds"
    // Add other keys and their corresponding legends here
  };
  return legends[key] || key;
};

const getLegendTooltip = (key: keyof DataType): string => {
  const tooltips: { [key in keyof DataType]?: string } = {
    tremor_dyskinesia: "Tremor & Dyskinesia is collected from the Apple Watch",
    mobility:
      "Mobility is collected from the iPhone when the user is actively moving",
    sleep: "Sleep is collected from the Apple Watch",
    medication: "Medications are manually reported by the patient"
  };
  return tooltips[key] || key;
};

const isActive = (
  entry: DataType,
  startDate: Date | null,
  endDate: Date | null,
  enabled: boolean
) => {
  const date = new Date(entry.date).getTime();
  // tooltips should only be shown for dates within the project start and end dates
  // or if the project start and end dates are not set
  let afterProjectStart = true;
  let beforeProjectEnd = true;
  if (startDate && enabled) {
    afterProjectStart = date >= startDate.getTime();
  }
  if (endDate && enabled) {
    beforeProjectEnd = date <= endDate.getTime();
  }

  return afterProjectStart && beforeProjectEnd;
};

const getTooltipText = (
  entry: DataType,
  key: keyof DataType,
  userCanSeePII: boolean
): string => {
  if (key === "medication" && entry.medication) {
    if (!userCanSeePII) {
      // If the user cannot see PII, we display the total number of medications
      const totalMedicationCount = Object.values(entry.medication).reduce(
        (sum, count) => sum + count,
        0
      );
      return `<b>${new Date(
        entry.date
      ).toLocaleDateString()}</b><br />Total Medication: ${totalMedicationCount}`;
    } else {
      // If the user can see PII, we display the details of the medications
      const medicationDetails = Object.entries(entry.medication)
        .map(([med, count]) => `${med}: ${count}`)
        .join("<br />");
      return `<b>${new Date(
        entry.date
      ).toLocaleDateString()}</b><br />${medicationDetails}`;
    }
  }

  // If the key is not medication, we display the value of the key (duration in hours)
  return `<b>${new Date(
    entry.date
  ).toLocaleDateString()}</b><br />${getLegendText(key)}: ${
    isNaN(entry[key] as number) || entry[key] === 0
      ? "None"
      : `${Math.round(entry[key] as number)} hours`
  }`;
};

const hasCustomMedication = (
  medications: Record<string, number> | undefined
): boolean => {
  if (!medications) return false;
  return Object.keys(medications).some(
    (med) => med.includes("(C)") || med.includes("Custom Event")
  );
};

const DataAvailabilityHeatmapCalendarField = (props: {
  projectDisplayConfig: ProjectDisplayConfig;
  dataAvailability: string;
  projectStart: Date;
}) => {
  const { projectDisplayConfig, dataAvailability } = props;
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { data: user } = useGetIdentity();

  const isAdmin =
    user?.defaultMembership?.role.admin ||
    user?.defaultMembership?.role.superAdmin;
  const canSeePII = user?.defaultMembership?.role.canSeePHI;

  const patientId = get(record, "id");
  const { patientStartEndDate } = useFlags();
  const projectStart =
    patientStartEndDate && get(record, "projectStartTime")
      ? new Date(get(record, "projectStartTime") * 1000)
      : null;
  const projectEnd =
    patientStartEndDate && get(record, "projectEndTime")
      ? new Date(get(record, "projectEndTime") * 1000)
      : null;
  const patientOnboardedDate = get(record, "createdAt")
    ? new Date(get(record, "createdAt") * 1000)
    : null;
  const [tooltip, setTooltip] = useState<{
    text: string;
    x: number;
    y: number;
  } | null>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const [availabilityData, setAvailabilityData] = useState<any[]>([]);
  const dataKeys: (keyof DataType)[] = [];

  Object.keys(projectDisplayConfig["dataTypes"]).forEach((key) => {
    const configKey = key as keyof ProjectDisplayConfig["dataTypes"];
    if (projectDisplayConfig["dataTypes"][configKey].enabled) {
      dataKeys.push(configKey);
    }
  });

  const fixedOrder: (keyof DataType)[] = [
    "tremor_dyskinesia",
    "mobility",
    "sleep",
    "medication"
  ];
  const orderedDataKeys = fixedOrder.filter((key) => dataKeys.includes(key));

  const monthLabels = getMonthLabelPositions(availabilityData);
  const handleMouseEnter = (
    e: React.MouseEvent,
    key: keyof DataType,
    entry: DataType
  ) => {
    if (
      svgRef.current &&
      isActive(entry, projectStart, projectEnd, patientStartEndDate)
    ) {
      const rect = svgRef.current.getBoundingClientRect();
      setTooltip({
        text: getTooltipText(entry, key, canSeePII),
        x: e.clientX - rect.left + 10,
        y: e.clientY - rect.top + 10
      });
    }
  };

  const handleCellClick = (key: keyof DataType, entry: DataType) => {
    const clickedDate = new Date(entry.date);
    const oneDayBefore = new Date(clickedDate);
    oneDayBefore.setDate(clickedDate.getDate() - 1);
    const oneDayAfter = new Date(clickedDate);
    oneDayAfter.setDate(clickedDate.getDate() + 1);

    const formatDate = (date: Date) => date.toISOString().split("T")[0];

    const date = formatDate(clickedDate);
    const startDate = formatDate(oneDayBefore);
    const endDate = formatDate(oneDayAfter);

    // We determine the portal url based on the environment (specifically the graphQL endpoint from the config)
    const carrotGraphUrl =
      (window as any).Rune?.Carrot?.config?.carrotGraph?.host || "";
    const strivePdPortalUrl = carrotGraphUrl.replace("graph", "app");

    switch (key) {
      case "medication":
        window.open(
          `https://${strivePdPortalUrl}/patients/${entry.patientId}/log?startTime=${startDate}&endTime=${endDate}`,
          "_blank"
        );
        break;
      case "tremor_dyskinesia":
        window.open(
          `/#/Patient/${entry.patientId}/show/3?date=${date}`,
          "_blank"
        );
        break;
      default:
        window.open(
          `https://${strivePdPortalUrl}/patients/${entry.patientId}/daily?startTime=${startDate}&endTime=${endDate}`,
          "_blank"
        );
    }
  };

  const cellSize = 15; // Size of each cell in the grid
  const padding = 5;
  const HORIZONTAL_OFFSET = 110;

  const [legendTooltip, setLegendTooltip] = useState<{
    text: string;
    key: keyof DataType;
  } | null>(null);

  const tooltipTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleLegendMouseEnter = useCallback((key: keyof DataType) => {
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
    setLegendTooltip({
      text: getLegendTooltip(key),
      key: key
    });
  }, []);

  const handleLegendMouseLeave = useCallback(() => {
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
    tooltipTimeoutRef.current = setTimeout(() => {
      setLegendTooltip(null);
    }, 300); // Delay before hiding the tooltip
  }, []);

  const isSmallScreen = useMediaQuery("(max-width:1500px)");

  useEffect(() => {
    const enabledCategories = (
      Object.keys(
        projectDisplayConfig["dataTypes"]
      ) as (keyof ProjectDisplayConfig["dataTypes"])[]
    ).filter(
      (key) =>
        projectDisplayConfig["dataTypes"][
          key as keyof ProjectDisplayConfig["dataTypes"]
        ].enabled
    );

    setAvailabilityData([]);

    const fetchStreamData = async () => {
      const streamParamsFunctions = {
        tremor_dyskinesia: getTremorStreamParams,
        mobility: getMobilityStreamParams,
        sleep: getSleepStageStreamParams
      };

      let { startDate } = getDataAvailabilityConfiguration(
        props.dataAvailability,
        props.projectStart
      );
      const { aggregateWindowResolution } = getDataAvailabilityConfiguration(
        props.dataAvailability,
        props.projectStart
      );

      // Adjust start date based on screen size
      const now = new Date();
      startDate = isSmallScreen
        ? new Date(now.setMonth(now.getMonth() - 2))
        : new Date(now.setDate(now.getDate() - 90));

      const browserTimezone = getBrowserTimezone();
      const { startDateTimestamp, endDateTimestamp } =
        deriveTimestampsFromDate(startDate);

      const startTime = startDateTimestamp;
      const endTime = endDateTimestamp;

      const desiredCategories: (keyof ProjectDisplayConfig["dataTypes"])[] = [
        "tremor_dyskinesia",
        "mobility",
        "sleep"
      ];

      const streamIdsResults = await getStreamIdsForCategories({
        dataProvider,
        desiredCategories,
        enabledCategories,
        patientId,
        streamParamsFunctions
      });

      const streamsDataPromises: {
        category: string;
        promise: Promise<any>;
      }[] = [];

      // Retrieve data for each Stream ID using getAggregateWindow
      for (const streamIdsByCategory of streamIdsResults) {
        if (
          streamIdsByCategory["category"] === "tremor_dyskinesia" ||
          streamIdsByCategory["category"] === "mobility" ||
          streamIdsByCategory["category"] === "sleep"
        ) {
          // We check that there is only one stream ID
          if (streamIdsByCategory["streamIds"]["data"].length > 1) {
            console.log("streamIdsByCategory", streamIdsByCategory);
            console.error(
              `Expected one stream ID MAXIMUM for ${streamIdsByCategory["category"]}`
            );
            continue;
          }
          if (streamIdsByCategory["streamIds"]["data"].length === 0) {
            continue;
          }
          const promise = dataProvider.getStreamAggregateWindow(
            streamIdsByCategory["streamIds"]["data"][0]["id"],
            {
              start_time: JSON.stringify(startTime),
              end_time: endTime,
              resolution: JSON.stringify(aggregateWindowResolution),
              aggregate_function: "sum",
              // TODO: SW-3563: Timezone are currently not handled correctly.
              // It should be taking into account the timezone of the user instead of being the browser timezone.
              // A full revamp/revisit/refactor of the timezone management is expected
              timezone_name: browserTimezone
            }
          );
          streamsDataPromises.push({
            category: streamIdsByCategory["category"],
            promise
          });
        }
      }

      const streamsDataResults = await Promise.all(
        streamsDataPromises.map((p) => p.promise)
      );

      // Initialize availabilityData based on the retrieved data from streams
      const nanoSecondsPerHour = 1e9 * 60 * 60;
      const newAvailabilityData = streamsDataResults
        .map((streamData, index) => {
          const category = streamsDataPromises[index].category;

          const { time, duration_sum } = streamData.json.data;

          return time.map((timestamp: number, index: number) => ({
            patientId: patientId,
            date: new Date(timestamp * 1000).toISOString().split("T")[0], // Convert UNIX timestamp to ISO date string
            [category]: duration_sum[index] / nanoSecondsPerHour
          }));
        })
        .flat();

      if (projectDisplayConfig["dataTypes"]["medication"]?.enabled) {
        const medicationData = await dataProvider.getManyReference(
          "PatientEvent",
          {
            target: "patient",
            id: patientId,
            pagination: {
              page: 1,
              perPage: 100
            },
            sort: {
              field: "createdAt",
              order: "DESC"
            },
            filter: {},
            meta: {
              enablePiiToggle: canSeePII
            }
          }
        );

        const filteredMedicationData = medicationData.data.filter(
          (event) =>
            event.classification?.category === "medication" &&
            event.duration.startTime > startDateTimestamp
        );

        filteredMedicationData.forEach((medicationEvent) => {
          const date = new Date(medicationEvent.duration.startTime * 1000)
            .toISOString()
            .split("T")[0];

          let medicationDisplayName = medicationEvent.displayNote;

          // Check if the medication event is a custom event and has a custom display name
          // If so, set the medicationDisplayName to the custom display name with "(C)" appended
          // The presence of "(C)" is used to determine if custom medications are present in `hasCustomMedication(...)`
          if (
            medicationEvent.displayName == "Custom Event" &&
            medicationEvent.customDetail?.displayName
          ) {
            medicationDisplayName = `${medicationEvent.customDetail.displayName} (C)`;
          }

          if (medicationEvent.method == "autolog")
            medicationDisplayName = `${medicationDisplayName} (A)`;

          const existingEntry = newAvailabilityData.find(
            (entry) => entry.date === date
          );
          if (existingEntry) {
            if (!existingEntry.medication) {
              existingEntry.medication = {};
            }
            medicationDisplayName in existingEntry.medication
              ? (existingEntry.medication[medicationDisplayName] += 1)
              : (existingEntry.medication[medicationDisplayName] = 1);
          } else {
            newAvailabilityData.push({
              patientId: patientId,
              date: date,
              medication: {
                [medicationDisplayName]: 1
              }
            });
          }
        });
      }

      // Aggregate data by date
      const dataByDate = newAvailabilityData.reduce((acc, entry) => {
        const { date, patientId, ...rest } = entry;
        if (!acc[date]) {
          acc[date] = { date, patientId }; // Initialize with date and patientId
        }
        // Merge entries by category for the same date
        Object.assign(acc[date], rest);
        return acc;
      }, {});

      // Convert the object back to array
      const aggregatedData: DataType[] = Object.values(dataByDate);

      // Sort the array by date
      aggregatedData.sort(
        (a: DataType, b: DataType) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      setAvailabilityData(aggregatedData);
    };

    fetchStreamData();
  }, [
    dataProvider,
    projectDisplayConfig,
    patientId,
    dataAvailability,
    isSmallScreen
  ]);

  return (
    <div style={{ position: "relative" }}>
      <svg
        ref={svgRef}
        width={availabilityData.length * cellSize + HORIZONTAL_OFFSET + 20}
        height={dataKeys.length * (cellSize + padding) + 20}
      >
        {monthLabels.map((label, index) => (
          <text
            key={`${patientId}-month-${label.text}-${index}`}
            x={label.position * cellSize + HORIZONTAL_OFFSET}
            y={15}
            fill="#666"
          >
            {label.text}
          </text>
        ))}
        {orderedDataKeys.map((key, rowIndex) => (
          <React.Fragment key={key + rowIndex}>
            <g
              transform={`translate(50, ${
                rowIndex * (cellSize + padding) + 17
              })`}
            >
              <g
                onMouseEnter={() => handleLegendMouseEnter(key)}
                onMouseLeave={handleLegendMouseLeave}
                style={{ cursor: "help" }}
              >
                <rect x="-5" y="0" width="60" height="20" fill="transparent" />
                <text x="0" y="15" fontSize="12" fill="#000">
                  {getLegendText(key)}
                </text>
              </g>
            </g>
            {availabilityData.map((entry, colIndex) => (
              <g
                key={`${patientId}-${key}-${entry.date}-${rowIndex}-${colIndex}`}
              >
                <rect
                  x={colIndex * cellSize + HORIZONTAL_OFFSET}
                  y={rowIndex * (cellSize + padding) + 20}
                  width={cellSize}
                  height={cellSize}
                  fill={getColor(
                    key === "medication"
                      ? entry[key]
                        ? Object.values(
                            entry[key] as Record<string, number>
                          ).reduce((a, b) => a + b, 0)
                        : 0
                      : (entry[key] as number),
                    projectDisplayConfig["dataTypes"][
                      key as keyof ProjectDisplayConfig["dataTypes"]
                    ].threshold as number,
                    entry.date,
                    patientOnboardedDate,
                    projectStart,
                    projectEnd,
                    key,
                    projectDisplayConfig
                  )}
                  stroke="white"
                  onMouseEnter={(e) => handleMouseEnter(e, key, entry)}
                  onMouseLeave={() => setTooltip(null)}
                  // We only want to allow admins and clinicians to click on the cells to go to the patient record
                  onClick={
                    isAdmin ? () => handleCellClick(key, entry) : undefined
                  }
                  style={{ cursor: isAdmin ? "pointer" : "default" }}
                />
                {/* For the medication swimlane, we display a custom triangle if the user has custom medications */}
                {key === "medication" &&
                  isActive(
                    entry,
                    projectStart,
                    projectEnd,
                    patientStartEndDate
                  ) &&
                  hasCustomMedication(entry[key] as Record<string, number>) && (
                    <polygon
                      points={`
                      ${
                        colIndex * cellSize + HORIZONTAL_OFFSET + cellSize - 7
                      },${rowIndex * (cellSize + padding) + 20}
                      ${colIndex * cellSize + HORIZONTAL_OFFSET + cellSize},${
                        rowIndex * (cellSize + padding) + 20
                      }
                      ${colIndex * cellSize + HORIZONTAL_OFFSET + cellSize},${
                        rowIndex * (cellSize + padding) + 27
                      }
                    `}
                      fill={COLORS.BLUEBERRY_300}
                    />
                  )}
                {isFirstOfMonth(entry.date) && (
                  <line
                    x1={colIndex * cellSize + HORIZONTAL_OFFSET}
                    y1={rowIndex * (cellSize + padding) + padding * 4}
                    x2={colIndex * cellSize + HORIZONTAL_OFFSET}
                    y2={
                      rowIndex * (cellSize + padding) + padding * 4 + cellSize
                    }
                    stroke="black"
                    strokeWidth={2}
                  />
                )}
                {isMonday(entry.date) && (
                  <line
                    x1={colIndex * cellSize + HORIZONTAL_OFFSET}
                    y1={rowIndex * (cellSize + padding) + padding * 4}
                    x2={colIndex * cellSize + HORIZONTAL_OFFSET}
                    y2={
                      rowIndex * (cellSize + padding) + padding * 4 + cellSize
                    }
                    stroke="gray"
                    strokeWidth={1}
                  />
                )}
              </g>
            ))}
          </React.Fragment>
        ))}
      </svg>
      {tooltip && (
        <div
          style={{
            position: "absolute",
            left: tooltip.x,
            top: tooltip.y - 50,
            padding: "5px",
            border: "1px solid gray",
            backgroundColor: "white"
          }}
          dangerouslySetInnerHTML={{ __html: tooltip.text }}
        />
      )}
      {legendTooltip && (
        <div
          onMouseEnter={() => handleLegendMouseEnter(legendTooltip.key)}
          onMouseLeave={handleLegendMouseLeave}
          style={{
            position: "absolute",
            left: 80,
            top:
              orderedDataKeys.indexOf(legendTooltip.key) *
                (cellSize + padding) +
              5,
            padding: "5px",
            border: "1px solid gray",
            backgroundColor: "white",
            zIndex: 1000,
            borderRadius: "4px",
            fontSize: "12px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
          }}
        >
          {legendTooltip.text}
        </div>
      )}
    </div>
  );
};

export default DataAvailabilityHeatmapCalendarField;
