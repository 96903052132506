import { useEffect, useState } from "react";
import { useDataProvider, useRecordContext } from "react-admin";
import { get } from "lodash";
import { Typography } from "@mui/material";
import {
  getDataAvailabilityConfiguration,
  getStreamIdsForCategories,
  getTapTestStreamParams
} from "src/providers/streams/runeStreamsUtils";
import { DataRecencyField } from "./DataRecencyField";
import { deriveTimestampsFromDate } from "src/utils/dateTimeUtils";
import { TimeInterval } from "src/constants";
import { ProjectDisplayConfig } from "../resources/project/ProjectShow/ProjectDisplayConfigurationDialog";

const TapTestField = (props: {
  projectStart: Date;
  source: string;
  label: string;
  category: string;
}) => {
  const { projectStart, source, label, category } = props;
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [testCount, setTestCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const patientId = get(record, "id");

  useEffect(() => {
    const fetchStreamData = async () => {
      const { startDate } = getDataAvailabilityConfiguration(
        TimeInterval.NINETY_DAYS,
        projectStart
      );

      const { startDateTimestamp, endDateTimestamp } =
        deriveTimestampsFromDate(startDate);

      const tapTest: (keyof ProjectDisplayConfig["dataTypes"])[] = ["tap_test"];

      const streamIdsResults = await getStreamIdsForCategories({
        dataProvider,
        desiredCategories: tapTest,
        enabledCategories: tapTest,
        patientId,
        streamParamsFunctions: {
          tap_test: getTapTestStreamParams
        }
      });

      const streamsDataPromises = streamIdsResults
        .map((streamIdsByCategory) => {
          if (streamIdsByCategory["streamIds"]["data"].length > 1) {
            console.error(
              `Expected one stream ID MAXIMUM for ${streamIdsByCategory["category"]}`
            );
            return null;
          }
          if (streamIdsByCategory["streamIds"]["data"].length === 0) {
            return null;
          }
          return dataProvider.getStream(
            streamIdsByCategory["streamIds"]["data"][0]["id"],
            {
              startTime: JSON.stringify(startDateTimestamp),
              endTime: endDateTimestamp,
              format: "json"
            }
          );
        })
        .filter(Boolean);

      Promise.all(streamsDataPromises)
        .then((streamsDataResults) => {
          if (streamsDataResults.length > 0) {
            setTestCount(streamsDataResults[0]?.json?.cardinality);
          }
        })
        .catch((error) => {
          console.error("Error fetching tap test data", { error });
          setError(error);
          return error;
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchStreamData();
  }, [dataProvider, patientId]);

  if (loading) {
    return <Typography variant="body2">Loading...</Typography>;
  }

  return (
    <DataRecencyField
      {...{ source, label, category, includeComplianceFlag: false }}
    >
      {!error && testCount > 0 && (
        <Typography component="div" variant="body2">
          {testCount} in 90 days
        </Typography>
      )}
    </DataRecencyField>
  );
};

export default TapTestField;
